<template>
  <div>
    <div class="floating-icon">
      <el-button type="primary" icon="el-icon-folder-add" @click="dialogVisible = true"/>
      <el-button type="danger" icon="el-icon-delete" @click="delPhotoAlbumAssets"/>
    </div>
    <div class="block"
         v-loading="loading"
         element-loading-text="稍等, 正在拼了命的回忆 !"
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(220,220,220, 0.8)">
      <el-timeline id="listUl" style="overflow-x: hidden; overflow-y: auto;" :style="'height:' + ULHeight">
        <el-checkbox-group v-model="checkList">
          <div v-for="(iter, index) in segmentList" :key="index">
            <el-timeline-item :timestamp="iter.paSCreationTime" placement="top" v-if="iter.list.length > 0">
              <el-card>
                <p class="p_desc">{{ iter.paSDesc }}</p>
                <el-divider/>
                <div class="image-gallery">
                  <el-row :gutter="4" class="image-row">
                    <el-col :span="spanNumber" v-for="(image, indexI) in iter.list" :key="indexI"
                            style="padding: 2px 2px">
                      <div class="image-wrapper" style="position: relative;">
                        <div @click="checkAdd(image.paAid)">
                          <div :class="[showDelCheckbox? 'pointer-events' : '']" style="position: relative;">
                            <el-image :src="image.paAFilePath" fit="fill"
                                      :style="'width: 100%;height:'  + imgHeight + 'px'"
                                      v-if="urlIsImg(image.paAFilePath)"
                                      :preview-src-list="srcList" lazy/>
                            <video :src="image.paAFilePath" preload="metadata" @click="cardPreview(image)"
                                   :style="'width: 100%;  object-fit: fill;  height:'  + imgHeight + 'px'" v-else>
                            </video>
                          </div>
                        </div>
                        <el-checkbox class="child" name="checkbox" :label="image.paAid"
                                     v-if="showDelCheckbox"/>
                        <i class="child-center el-icon-video-camera-solid"
                           @click="!showDelCheckbox ? cardPreview(image) : ''" v-if="!urlIsImg(image.paAFilePath)"/>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </el-card>
            </el-timeline-item>
          </div>
        </el-checkbox-group>
      </el-timeline>
    </div>

    <el-dialog
        :visible.sync="dialogVisible"
        :fullscreen="true"
        :destroy-on-close="true"
        :before-close="handleClose">
      <span>
        <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入关于这段记录的描述~"
            :autosize="{ minRows: 2}"
            v-model="addDesc">
</el-input>
        <el-divider/>

<el-upload style="text-align: center"
           class="upload-demo"
           ref="upload"
           action="/api/file/saveToTemp"
           :on-preview="handlePictureCardPreview"
           :on-remove="handleRemove"
           :before-upload="handleBeforeUpload"
           :file-list="fileList"
           :multiple="true"
           :list-type="'picture-card'"
           :accept="'video/*,image/*'"
>
    <i class="el-icon-upload"></i>
  <!-- 使用 scoped-slot 设置视频缩略图模板 -->
    <template slot='file' slot-scope='{file}'>
      <div class="video-thumbnail" v-if="isVideo(file)">
        <video :src="file.thumbnail" controls preload="metadata"
               style="width: 100%; height: 100%; object-fit: cover;"></video>
        <span>{{ file.name }}</span>
      </div>
      <div v-else>
        <img :src="file.thumbnail" style="width: 100%; height: 100%; object-fit: cover;"/>
      </div>
       <span class="el-upload-list__item-actions">
        <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
        >
          <i class="el-icon-zoom-in"></i>
        </span>
        <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
        >
          <i class="el-icon-delete"></i>
        </span>
      </span>
    </template>
  </el-upload>
        <el-progress v-if="showProgress" :percentage="uploadPercentage" :show-text="true"/>
      </span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submitUpload">确 定</el-button>
  </span>
    </el-dialog>


    <el-dialog
        :visible.sync="showDialogVisible"
        width="100%"
        custom-class="charon_drawer"
        :destroy-on-close="true"
        :before-close="closeVideo">
      <div>
        <el-row type="flex" class="row-bg" justify="center">
          <el-col :xs="24" :sm="18" :md="12" :lg="8" :xl="8" v-if="showFileTypeVideo">
            <Artplayer ref="artplayer" :muted="true"/>
          </el-col>
          <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" v-else>
            <el-image :src="this.showFile.thumbnail" style="width: 100%; height: 100%; "
                      fit="fit"/>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PhotoAlbum from "../../../request/expand/photoAlbum";
import Artplayer from "../../../components/Artplayer.vue";
import CryptoUtil from "@/utils/cryptoUtil";

export default {
  name: "souvenir",
  components: {
    Artplayer,
  },
  data() {
    return {
      cryptoU: new CryptoUtil(),
      ask: new PhotoAlbum(),
      segmentList: [],
      spanNumber: 0,
      value1: 0,
      pageInfo: {
        indexI: 1,
        pages: 0
      },
      dialogVisible: false,
      dialogImageUrl: '',
      fileList: [],
      disabled: false,
      showDialogVisible: false,
      showFile: null,
      showFileTypeVideo: true,
      uploadPercentage: 0,
      showProgress: false,
      checkAll: false,
      showDialogHeightI: 0,
      srcList: [],
      showDelCheckbox: false,
      checkList: [],
      addDesc: "",
      loading: true
    }
  },
  created() {
    this.getSpan()
    window.onresize = () => {
      this.getSpan()
    };
  },
  mounted() {
    this.loadData(1)
    const this_ = this
    document.getElementById("listUl").addEventListener('scroll', function () {
      const ul = document.getElementById('listUl');
      const isBottom = Math.ceil(ul.scrollTop + ul.clientHeight) >= ul.scrollHeight
      if (isBottom && this_.pageInfo.pages > this_.pageInfo.indexI) {
        this_.loadData(++this_.pageInfo.indexI);
      }
    })
  },
  methods: {
    checkAdd(paAId) {
      if (this.showDelCheckbox) {
        let index = this.checkList.indexOf(paAId);
        if (index !== -1) {
          // 如果存在，则删除该数字
          this.checkList.splice(index, 1);
        } else {
          // 如果不存在，则添加该数字
          this.checkList.push(paAId);
        }
        return
      }
    },
    cardPreview(image) {
      this.showFile = {"thumbnail": image.paAFilePath};
      this.showFileTypeVideo = true
      this.showDialogVisible = true;
      if (this.showFileTypeVideo) {
        this.$nextTick(function () {
          this.$refs.artplayer.changeUrl(this.showFile.thumbnail)
        })
      }
    },
    delPhotoAlbumAssets() {
      if (!this.showDelCheckbox) {
        this.showDelCheckbox = true
        return
      }
      if (this.checkList.length == 0) {
        this.showDelCheckbox = false
        return
      }

      this.$confirm('确认删除？')
          .then(_ => {
            this.ask.delPhotoAlbumAssets(this.checkList).then(res => {
              if (res.code == 200) {
                this.$message({
                  showClose: true,
                  type: 'success',
                  message: '删除成功!'
                });
                this.segmentList = []
                this.srcList = []
                this.loadData(1)
              }
            })
          })
          .catch(_ => {
            this.checkList = []
          });

    },
    isVideo(file) {
      return file.type.startsWith('video/');
    },
    submitUpload() {
      const formData = new FormData();
      this.fileList.forEach(item => {
        formData.append('file', item);
      })
      if (this.$store.state.user.isLogin) {
        formData.append('paId', this.$route.query.paId);
        formData.append('desc', this.addDesc);
      }
      const xhr = new XMLHttpRequest();
      this.showProgress = true
      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          this.uploadPercentage = Math.round((event.loaded * 100) / event.total);
        }
      });
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            let result = JSON.parse(xhr.response);
            this.dialogVisible = false
            this.segmentList = []
            this.fileList = []
            this.srcList = []
            this.uploadPercentage = 0
            this.showProgress = false
            this.addDesc = ""
            this.loadData(1)
          } else {
            this.$message({
              showClose: true,
              message: '文件上传失败',
              type: 'error'
            });
          }
        }
      };
      xhr.open('POST', "/api/photoAlbum/saveSegment", true);
      xhr.send(formData);
    },
    handleBeforeUpload(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const thumbnail = e.target.result; // 获取缩略图
        // 将缩略图添加到file对象中
        file.thumbnail = thumbnail;
        // 更新文件列表
        this.fileList.push(file);
      };
      reader.readAsDataURL(file);
      return false; // 阻止默认上传行为
    },
    handleRemove(file) {
      const index = this.fileList.findIndex((profile) => profile.name === file.name);
      if (index !== -1) {
        this.fileList.splice(index, 1)
      }
    },
    handlePictureCardPreview(file) {
      this.showFile = file;
      this.showFileTypeVideo = this.isVideo(file)
      this.showDialogVisible = true;
      if (this.showFileTypeVideo) {
        this.$nextTick(function () {
          this.$refs.artplayer.changeUrl(file.thumbnail)
        })
      }
    },
    closeVideo(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            if (this.showFileTypeVideo) {
              // Artplayer组件销毁后但网络请求仍在进行处理
              const videoElement = document.querySelector('.art-video'); // 获取视频元素
              if (videoElement) {
                videoElement.src = ''; // 清空视频源
                videoElement.load(); // 重新加载视频
              }
            }
            done();
          })
          .catch(_ => {
          });
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    },
    loadData(val) {
      let data = {
        "paId": this.$route.query.paId,
        "pageNum": val,
        "pageSize": 5
      }
      this.loading = true
      this.ask.getPhotoAlbumSegmentByPaId(data).then(res => {
        const appToken = this.cryptoU.decryptFun(this.$route.query.appToken)
        res.list.forEach(item => {
          this.segmentList.push(item)
          item.list.forEach(iter => {
            iter['paAFilePath'] = iter['paAFilePath'].replace(/\\/g, '/');
            iter['paAFilePath'] = "/api/resources/" + iter['paAFilePath'] + "?app=" + encodeURIComponent(appToken.substring(1, appToken.length - 1))
            this.srcList.push(iter['paAFilePath'])
          })

        })
        this.pageInfo.pages = res.pages
        this.loading = false
      }).catch(error => {
        this.$message.error('加载出现错误!');
        this.loading = false
      })
    },
    getSpan() {
      const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (width < 250) {
        this.spanNumber = 24; // 小屏幕，每行显示1张图片
      } else if (width < 350) {
        this.spanNumber = 12; // 小屏幕，每行显示2张图片
      } else if (width < 530) {
        this.spanNumber = 8; // 中等屏幕，每行显示3张图片
      } else if (width < 850) {
        this.spanNumber = 6; // 大屏幕，每行显示4张图片
      } else {
        this.spanNumber = 4; // 大屏幕，每行显示4张图片
      }
    },
    urlIsImg(url) {
      const fileNameMatch = url.match(/\/([^/?#]+)[^/]*$/);
      if (!fileNameMatch) {
        // 如果匹配失败，返回未知文件类型
        return 'unknown';
      }
      const fileName = fileNameMatch[1]; // 获取文件名部分
      const extensionMatch = fileName.match(/\.(\w+)$/); // 匹配文件扩展名
      if (!extensionMatch) {
        // 如果没有匹配到文件扩展名，返回未知文件类型
        return 'unknown';
      }
      const extension = extensionMatch[1].toLowerCase(); // 获取文件扩展名并转换为小写
      if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
        return true;
      } else if (['mp4', 'mov', 'avi'].includes(extension)) {
        return false;
      }
    }
  },
  computed: {
    imgHeight() {
      const number = 48 + (24 / this.spanNumber) * 14
      return number
    },
    ULHeight() {
      return window.innerHeight - 60 + 'px';
    },

  }
}
</script>

<style scoped>
ul {
  padding-inline-start: 0
}

/deep/ .el-card__body {
  padding: 5px 5px 5px 5px;
}

.image-list .el-card .image-wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  position: relative;
  overflow: hidden;
}

.image-list .el-card .image-wrapper img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.floating-icon {
  position: fixed;
  right: 30px;
  bottom: 50px;
  z-index: 1000;
  cursor: pointer;
}

.video-thumbnail {
  position: relative;
  width: 100%;
  height: 150px;
}

.video-thumbnail video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-thumbnail span {
  position: absolute;
  bottom: 5px;
  left: 5px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2px 5px;
  font-size: 12px;
}


.child {
  position: absolute;
  top: 0;
  right: 0;
}

.child-center {
  position: absolute;
  top: 25%;
  right: 25%;
  font-size: 50px;
}

/deep/ .charon_drawer > .el-drawer__header {
  margin: 0;
  padding: 0;
}

/deep/ .charon_drawer > .el-dialog__body {
  margin: 0;
  padding: 0;
}

/deep/ .charon_drawer > .el-dialog__header {
  display: none;
}

/deep/ .el-checkbox__label {
  display: none;
}

.pointer-events {
  pointer-events: none
}

.p_desc {
  font-size: 14px;
  margin: 0;
}

.el-divider {
  margin: 4px 0px;
}
</style>